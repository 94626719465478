import { graphql } from "gatsby";
import React from "react";
import Button from "../components/Button";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

const Innsendt = ({
  data: {
    sanityRegister: {
      receivedTitle: title,
      receivedDescription: description,
      receivedButton: buttonText,
    },
  },
}) => {
  return (
    <>
      <Nav />
      <div className="min-h-screen flex justify-center pt-top max-w-screen-lg mx-auto px-page">
        <div className="flex flex-col items-center">
          <h1 className="text-subheading-1 md:text-heading-4 text-center mb-4">
            {title}
          </h1>
          <p className="text-body-1 text-center">{description}</p>
          <Button to="/">{buttonText}</Button>
        </div>
      </div>
      <Footer />
      <CookieBanner />
    </>
  );
};

export const query = graphql`
  query {
    sanityRegister(_id: { regex: "/register$/" }) {
      receivedTitle
      receivedDescription
      receivedButton
    }
  }
`;

export default Innsendt;
